@charset "utf-8";

///////////////////////////
/// mainタグに使用
///////////////////////////

.l_main01 //Project詳細
{
  padding-top: 56px + 85px;
  @include v_mq(SP){
    padding-top: 90px;
  }
}

///////////////////////////
/// ボタンレイアウトに使用
///////////////////////////
.l_btn-group01 //SNSボタン
{
  display: flex;
  &__item01{
    margin-right: 20px;
    @include v_mq(SP){
      margin-right: 30px;
    }    
    @include lc{
      margin-right: 0;
    }
  }
}

.l_btn-group02 //記事一覧　前の記事、次の記事ボタン類
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__item01{

  }
}

.l_lo01 //News詳細
{
  border: 1px solid $border-color;
  border-left: none;
  border-right: none;
  padding: 28px 0 83px;
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
    padding: 14px 0 56px;
  }        
  &__left{
    width: 300 / (300 + 558) * 100%;
    max-width: 300px;
    @include v_mq(SP){
      margin-bottom: 14px;
    }
  }
  &__right{
    flex: 1;
    padding-right: 104px;
    @include v_mq(SP){
      padding-right: 0;
    }
  }
}


.l_lo02 //フッターコンテンツなど　プライバシーポリシー周り
{
  display: flex;
  justify-content: flex-end;
  @include v_mq(SP){
    flex-direction: column;
    align-items: center;
  }        
  &>a, small{
    margin-right: 30px;
    @include lc{
      margin-right: 0;
    }
    @include v_mq(SP){
      margin-right: 0;
    }
  }  
}

.l_lo03 //About きらめきと暮らす
{
  display: flex;
  margin-left: 104px;
  align-items: center;
  @include v_mq(SP){
    margin-left: 0;
  }        
  &__wrap01{

  }
  &__wrap02{
    margin-left: 62px;
  }  
}

.l_lo04 //About 美しく豊かな自然と、ものづくりが息づく場所。
{
  @include v_mq(SP){
    position: relative;
  }
  &__wrap01{
    margin-bottom: 46px;
    @include v_mq(SP){
      position: absolute;
      top: 0;
      left: 31px;
    }
  }
  &__wrap02{
    margin-right: 52px;
    img{
      width: 100%;
    }
    @include v_mq(SP){
      margin-right: 0;
      margin-left: 31px;
      margin-bottom: 31px;
      position: relative;
      &:before{
        content: "";
        display: block;
        width: 100%;
        padding-top: 125%;        
      }
    }
  }
  &__wrap03{
    @include v_mq(SP){
      position: absolute;
      top: 0;    
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;
      img{
        width: 135%;
        height: 100%;
        object-fit: cover;
        object-position: bottom left;
      }      
    }
  }
}

.l_lo05 //Project一覧 ホバーでメイン画像表示。
{
  &__wrap01{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    &:before{
      content: "";
      display: block;
      position: absolute;;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(#000, .35);
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;      
    }
  }
}

.l_lo06 //Project一覧 メインコンテンツ部のラッパー
{
  position: relative;
  z-index: 3;
}
