@charset "utf-8";

.l_footer{
  background-color: #fff;
  &__wrap01{}
  &__wrap02{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;
    img{
      width: 135px;
      padding: 0 65px;
      user-drag: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      @include v_mq(SP){
        width: 157px;
        padding: 52px 0;
      }
    }
    @include v_mq(SP){
      border-right: none;
      border-bottom: 1px solid $border-color;
    }
  }
  &__wrap03{}
  &__wrap04{}
  &__wrap05{
    display: flex;
    flex: 1;
    justify-content: space-between;
    @include v_mq(SP){
      flex-direction: column;
      align-items: center;
    }    
  }
  &__wrap06{
    width: 100%;
    padding: 56px 42px 32px 72px;
    box-sizing: border-box;
    @include v_mq(SP){
      padding: 51px 31px 24px;
    }
  }
  &__wrap07{
    display: flex;
    @include v_mq(SP){
      flex-direction: column;
    }
  }
}
