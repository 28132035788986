@charset "utf-8";

$bp01: 1000px;
$bp02: 750px;

$fs01: 12px;
$fs02: 14px;
$fs03: 16px;
$fs04: 20px;
$fs05: 22px;
$fs06: 24px;

$line-height: 1.8;

$red01: #CA3C18;
$blue01: #002062;
$blue02: #d7ebf5;
$blue03: #009be4;
$gray01: #f5f5f5;
$gray02: #efefef;
$gray03: #ebebeb;
$gray03: #ddd;
$gray04: #7b7b7b;
$white: #fff;

$pink: #dacccc;
$blue: #c7ccd2;

$fc01: #444;
$fc02: $blue01;
$fc03: $gray04;
$fc-base: #5A5A5A;

$baseC: #fdfdfd;

$focusColor: $blue03;

$border-color: #E5E5E5;
$bg-beige: #F9F9F3;