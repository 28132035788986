@charset "utf-8";

[data-js-top-loading]{
  // opacity: 0.5;
  [data-js-top-loading-wrap]{
    display: flex;
    width: 278px;
    height: auto;
    position: relative;
    @include v_mq(PC){
      transform: translateX(-2.4%);
    }
    &:before{
      content: "";
      display: block;
      width: 100%;
      padding-top: 150 / 117 * 100%;
    }
    @include v_mq(SP){
      width: 250px;
    }
    @include v_mq(OVER){
      width: 22%;
    }
  }
  #loading{
    width: 100%;
    position: absolute;
    height: 74.4%;
  }
}

[data-js-scroll-display-elm]{
  opacity: 0;
  &.is-show{
    animation-name:fadeUpAnime;
    animation-duration:0.5s;
    animation-fill-mode:forwards;
    animation-timing-function: linear;
    opacity: 0; 
    @keyframes fadeUpAnime{
      from {
      opacity: 0;
      transform: translateY(10px);
      }

      to {
      opacity: 1;
      transform: translateY(0);
      }
    }  
  }
}

[data-js-Ps-container]{
  position: relative;
  .ps__thumb-y {
    background-color: #dfd6ca;
  }
}

[data-js-hide-logo]{
  opacity: 0;
  transition: opacity .2s linear;
  &.is-show{
    opacity: 1;
  }
}

[data-js-toggle-menu]{
  display: none;
}

[data-js-hover-transiton01]{
  &.is-hover{
    @include v_mq(PC){
      transition: all .1s linear;
    }    
  }
  &.is-out{
    @include v_mq(PC){
      transition: all .25s linear;
    }
  }  
}

[data-js-add-error]{
  display: none;
}

.is-white[data-js-diplay-image-list-parent]{
  color: #fff;
  svg{
    path{
      fill: #fff;
    }
  }
}