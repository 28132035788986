@charset "utf-8";

.c_nav01 //ヘッダーgナビ
{    
  @include v_mq(SP){
    margin: 0;
  }
  ul{
    display: flex;
    justify-content: flex-end;
    @include v_mq(SP){
      justify-content: center;
    }
    li{
      margin-right: 30px;
      @include lc{
        margin-right: 0;
      }
      @include v_mq(SP){
        margin-right: 28px;
        width: 1em;
      }      
      a{
        font-size: 12px;
        font-family: 'Noto Sans CJK JP', sans-serif;
        font-weight: 400;
        text-decoration: none;
        color: $fc-base;
        transition: color 0.1s ease-out, background-size .1s linear;
        &.background--light {
          color: $fc-base;
        }
        &.background--dark {
          color: #fff;
        }        
        @include v_mq(PC){      
          &.is-hover{
            background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
            background-size: 100% 1px;  
          }
            &.is-out{
            background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
            background-size: 100% 0px;
          }  
        }           
        @include v_mq(SP){
          @include c_txt05;
          line-height: 1;
          color: $fc-base;
          &.is-hover{
            color: #D7A85F;
          }
          &.is-out{
            color: $fc-base;
          }            
        }
      }
    }
  }
  &.background--light {
    ul{
      li{   
        a{
          @include v_mq(PC){
            color: $fc-base;
            &.is-hover{
              background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
              background-size: 100% 1px;  
            }
            &.is-out{
              background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
              background-size: 100% 0px;
            }  
          }        
        }
      }
    }    
  }
  &.background--dark {
    ul{
      li{   
        a{
          @include v_mq(PC){
            color: #fff;
            &.is-hover{
              background: linear-gradient(#fff, #fff) 0 100% no-repeat;
              background-size: 100% 1px;  
            }
            &.is-out{
              background: linear-gradient(#fff, #fff) 0 100% no-repeat;
              background-size: 100% 0px;
            }
          }         
        }
      }
    }   
  }
}

.c_nav02  //フッターナビ
{
  ul{
    display: flex;
    li{
      margin-right: 33px;
      line-height: 1;
      width: 1em;
      @include lc{
        margin-right: 0;
      }
      @include v_mq(SP){
        margin-right: 28px;
      }
      a{
        @include c_txt05;
        text-decoration: none;
        &.is-hover{
          color: #D7A85F;
        }
        &.is-out{
          color: $fc-base;
        }      
      }
    }
  }
}