@charset "utf-8";

.l_fv01 //TOP FV
{
  position: relative;
  width: 100%;
  height: 100%;
  &:before{
    content: "";
    display: block;
    width: 32px;
    height: 18px;
    background-image: url(../images/scroll.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  @include v_mq(SP){

  }
  &__wrap01{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 10000;
    img{
      width: 278px;
      position: relative;
      @include v_mq(PC){
        transform: translateX(-2.4%);
      }
    }
    @include v_mq(SP){
      img{
        width: 250px;
      }
    }
    @include v_mq(OVER){
      img{
        width: 22%;
      }
    }
    &-ver01{
      background-color: $bg-beige;
      z-index: 10010;
      position: fixed;
      // display: none;
      @include v_mq(SP){
        img{
          width: 250px;
        }
      }
    }
    img{
      user-drag: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
    }
  }
  &__wrap02{
    width: 100%;
    height: 100%;    
    display:  flex;
    @include v_mq(SP){
      flex-direction: column;
    }
  }
  &__wrap03{
    width: 50%;
    height: 100%;
    @include v_mq(SP){
      width: 100%;
      height: 50%;
    }
  }
  &__wrap04{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }    
  }  
}

.l_fv02 //aboutなど FV
{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }  
  &__left{
    background-color: #fff;
    width: 404 / (404 + 962) * 100%;
    padding: 84px 60px 49px 33px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @include v_mq(SP){
      width: 100%;
      // height: 269 / (269 + 375) * 100%;
      position: relative;
      padding: 0 60px 49px 33px;
      &:before{
        content: "";
        display: block;
        padding-top: 269 / 379  * 100%;
      }
    }    
  }
  &__right{
    position: relative;
    width: 962 / (404 + 962) * 100%;
    &:before{
      content: "";
      display: block;
      padding-top: 683 / 962 * 100%;
    }    
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    @include v_mq(SP){
      width: 100%;
      &:before{
        padding-top: 100%;
      }      
    }     
  }
  &__inner{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &__wrap02{
    display: flex;
    justify-content: flex-end;
    @include v_mq(SP){
      justify-content: start;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__wrap03{
    height: 100%;
    display: flex;
    align-items: end;
    img{
      width: 184 / 1336 * 100vw;
    }
    @include v_mq(SP){
      img{
        width: 116 / 375 * 100vw;
      }
    } 
  }
  &__wrap04{
    width: 184 / 1336 * 100vw;
    @include v_mq(SP){
      width: 116 / 375 * 100vw;
    }
    svg{
      display: block;
    }
  }  
}