@charset "utf-8";

.u_d{
  &_none{
    &_PC{
      @include v_mq(PC){
        display: none !important;
      }
    }
    &_SP{
      @include v_mq(SP){
        display: none !important;
      }
    }
  }
}

.u_ta{
  &_c{
    text-align: center;
  }
}

@mixin set_mq_both {
  &pc{
    @include v_mq(PC){
      @content;
    }
  }
  &sp{
    @include v_mq(SP){
      @content;
    }
  }
}

@mixin u_margin($num){
  $prefixes: "m","mt","mr","mb","ml","mh","mv";
  @each $prefix in $prefixes{
    .u_#{$prefix}#{$num}{
      @include set_mq_both{
        @if $prefix == "m" {
          margin: #{$num}px;
        } @else if $prefix == "mt" {
          margin-top: #{$num}px;
        } @else if $prefix == "mr" {
          margin-right: #{$num}px;
        } @else if $prefix == "mb" {
          margin-bottom: #{$num}px;
        } @else if $prefix == "ml" {
          margin-left: #{$num}px;
        } @else if $prefix == "mh" {
          margin-right: #{$num}px;
          margin-left: #{$num}px;
        } @else if $prefix == "mv" {
          margin-top: #{$num}px;
          margin-bottom: #{$num}px;
        }
      }
    }
  }
}

@include u_margin(0);
@include u_margin(12);
@include u_margin(14);
@include u_margin(16);
@include u_margin(28);
@include u_margin(31);
@include u_margin(36);
@include u_margin(42);
@include u_margin(56);
@include u_margin(84);
@include u_margin(104);
@include u_margin(112);
@include u_margin(140);
@include u_margin(142);
@include u_margin(204);

.u_bb01 //親要素よりボーダーが短い場合に使用
{
  position: relative;
  padding-bottom: 140px;
  &:after{
    content: "";
    width: calc(100vw - 208px);
    height: 1px;
    background-color: $border-color;
    position: absolute;
    left: 0;
    right: 104px;
    bottom: 0;
  }
  @include v_mq(SP){
  padding-bottom: 84px;
    &:after{
      width: calc(100vw - 62px);
      left: 31px;
    }
  }
  &_ver01{
    &:after{
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}

.u_flex_bl //文字をベースラインに合わせたい時
{
  display: flex;
  align-items: baseline;
}

.u_tt_up{
  text-transform: uppercase;
}

.u_tt_cap{
  text-transform: capitalize;
}