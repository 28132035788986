@charset "utf-8";

.c_slide01{
  width: 100%;
  height: 100%;
  &__item01{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slick-list, .slick-track, div{
    height: 100%;
  }
}

.c_slide02{
  &__main{}
  &__indicator{
    display: flex;
    justify-content: right;
    padding-right: 104px;
    @include v_mq(SP){
      padding-right: 31px;
    }
  }
  &__wrap01{
    width: 30px;
    height: 6px;
    margin-right: 10px;
    position: relative;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #D7A85F;
      opacity: .3;
    }
    &.is_active{
      &:before{
        opacity: 1;
      }
      .c_slide02__item01{
        opacity: 1;
      }
    }
  }
  &__item01{
    opacity: 0;
    height: 6px;
    width: 6px;
    background-color: #D7A85F;
    border-radius: 50%;
  }
}