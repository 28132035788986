@charset "utf-8";

.l_header{
  position: fixed;
  width: 100%;
  z-index: 10005;
  transform: translateY(0);
  transition: all .3s ease; 
  &.is_hide{
    transform: translateY(-60px);
  }
  @include v_mq(PC){
    display: flex;
    justify-content: space-between;
    align-items: center;  
    margin-top: 28px;
    padding: 0 41px;
    box-sizing: border-box;
  }
  &__wrap01{
    @include v_mq(SP){
      margin: 0;
      position: fixed;
      background-color: #fff;
      width: 100%;
      height: calc(100% - 50px);
      padding-top: 50px;
      display: flex;
      flex-direction: column;
    }
  }
  &__wrap02{
    h1{
      line-height: 0;   
    }
    img{
      width: 338px;
      @include v_mq(SP){
        width: 218px;  
      }      
    } 
  }
  &__wrap03{
    @include v_mq(SP){
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__wrap04{
    display: flex;
    justify-content: center;
    @include v_mq(PC){
      display: none;
    }
  }
  &__wrap05{
    margin-bottom: 24px;
    @include v_mq(PC){
      display: none;
    }    
  }
  &__wrap06{
    @include v_mq(SP){
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: absolute;
      top: 28px;
      padding: 0 14px;
      box-sizing: border-box;
      z-index: 1;      
    }
  }
  &__btn01{
    @include v_mq(PC){
      display: none;
    }    
  }
}