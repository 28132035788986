@charset "utf-8";

input[type=text],input[type=email],input[type=tel],select{
  border-radius: 17px;
  border: 1px solid $border-color;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 7px 20px;
  height: 34px;
  @include  c_txt07;
  line-height: 1.2;
}

select{
  background-image: url(../images/icon_arrow03.svg);
  background-repeat: no-repeat;
  background-position: right 14px center;
  color: $fc-base !important;
}

textarea{
  border-radius: 6px;
  border: 1px solid $border-color;
  background-color: #fff;
  width: 100%;
  height: 195px;
  box-sizing: border-box;
  padding: 10px;
  @include  c_txt07;
}

.c_inputset01{
  display: flex;
  align-items: baseline;
  width: 100%;
  @include v_mq(SP){
    flex-direction: column;
  }
  &__left{
    margin-right: 43px;
    width: 156px;
    display: flex;
    align-items: center;
    @include v_mq(SP){
      justify-content: space-between;
      width: 100%;
      margin-bottom: 13px;
    }
    &-ver01{
      align-self: start;
    }
  }
  &__right{
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 156px;
    &-ver01{
      margin-right: 0;
      @include v_mq(SP){
        display: flex;
        width: 100%;
        flex-direction: column;
      }  
    }
  }
  &__name{
    display: inline-block;
    width: 100px;
  }
  &__txt01{
    display: inline-block;
    width: 56px;
    text-align: right;
  }
  &__txt02{
    display: inline-block;
    width: 156px;
    text-align: right;
    flex-shrink: 0;
    flex-grow: 0;
    @include v_mq(SP){
      margin-top: 11px;
      text-align: left;
      align-self: start;
    }
  }
  &__txt03{
    @include v_mq(SP){
      width: 100%;
      text-align: center;
    }
  }  
  &__wrap01{
    width: 100%;
  }
  &__wrap02{
    display: flex;
    width: 156px;
    margin-bottom: 28px;
    @include v_mq(SP){
      margin-bottom: 20px;
    }
  }  
}

.mw_wp_form{
  input,
  button,
  select,
  textarea {
    &:focus{
      box-shadow: none !important;
      border: solid 1px #5a5a5a !important;
    }
  }  
  br{
    display: none;
  }
  .error{
    @include c_txt01;
    color: #CA0404;
  }
  &.mw_wp_form_input{
    .is_hide-input{
      display: none;
    }
  }
  &.mw_wp_form_confirm{
    .c_inputset01__wrap01{
      @include c_txt07;
    }
    .c_inputset01__txt01{
      display: none;
    }
    .is_hide-confirm{
      display: none;
    }
  }
}

.mwform-checkbox-field{
  label //チェックボックス 横並び
  {
    position: relative;
    display: flex;
    align-items: center;
    @include c_txt07;
    &:before{
    }
    input[type=checkbox]{
      display: block;
      width: 22px;
      height: 22px;
      position: relative;  
      margin-right: 10px; 
      &:focus{
        border: none !important;
        &:before{
          border: solid 1px #5a5a5a !important;
        }
      }
      &:before{
        position: absolute;
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        border: 1px solid rgba(#D7A85F, 0.3);;
        background-color: #fff;
        border-radius: 5px;
      }      
      &:checked{
        &:after{
          content: "";
          display: block;
          width: 6px;
          height: 13px;
          /* background-color: #5a5a5a; */
          border: 2px solid #5a5a5a;
          border-left: none;
          border-top: none;
          transform: rotate(45deg);
          position: absolute;
          left: 8px;
          bottom: 4px;
        }    
      }
    }
  }
  .mwform-checkbox-field-text{
    display: block;
  }
}
