@mixin transition01 {
  transition: .3s;
}

@mixin transition02 {
  transition: 1s;
}

@mixin transition03 {
  transition: .5s;
}

@mixin transition04{
  transition: opacity 1s ease;
  &[data-show="false"]{
    opacity: 0;
  }
  &[data-show="true"]{
    opacity: 1;
  }
}

@mixin transition05{
  transition: opacity .5s ease;
  &[data-show="false"]{
    opacity: 0;
  }
  &[data-show="true"]{
    opacity: 1;
  }
}

@mixin btn01{
  padding: 16px 60px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
  color: $white;
  // font-weight: bold;
  background-color: #d49335;
  // background-color: #686460;
  // background-color: #37445a;
  // background-color: #231916;
  border: none;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  // border-radius: 30px;
  @include transition01;
  @include hover02;
  @include v_mq(PC){
    font-size: $fs03;
  }
  @include v_mq(SP){
    width: 100%;
    padding: 10px 15px;
    line-height: 1.6;
    text-align: center;
  }
  &_ver01{
    width: auto;
    padding: 15px 30px;
  }
}

@mixin btn02{
  padding: 15px 30px;
  margin: 0 auto;
  display: block;
  color: $fc01;
  background-color: $white;
  border: none;
  font-size: $fs00;
  @include transition01;
  @include hover02;
  @include v_mq(PC){
    font-size: $fs00;
  }
  @include v_mq(SP){
    padding: 15px 20px;
  }
}

@mixin hover01{
  color: $white;
  @include transition01;
  &:hover{
    opacity: 0.5;
    cursor: pointer;
  }
}

@mixin hover02{
  opacity: 1;
  @include transition01;
  &:hover{
    opacity: 0.8;
    cursor: pointer;
  }
}

@mixin hover03{
  text-decoration: none;
  @include transition01;
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin txt_var{
  &_bold{
    font-weight: bold;
  }
  &_red{
    color: $red01;
  }
}

@mixin mb{
  &_mb01{
    margin-bottom: 2.7%;
  }
  &_mb02{
    margin-bottom: 2%;
  }
}

$v_bps: (
  'SP': 'screen and (max-width: 1023px)',
  'PC': 'screen and (min-width: 1024px)',
  'OVER': 'screen and (min-width: 1300px)',
  'header_PC': 'screen and (min-width: 1001px)',
  'header_SP': 'screen and (max-width: 1000px)',
) !default;
@mixin v_mq($bp) {
  @media #{map-get($v_bps, $bp)} {
    @content;
  }
}

@mixin fc {
  &:first-child{
    @content;
  }
}

@mixin lc {
  &:last-child{
    @content;
  }
}