@charset "utf-8";

.c_heading01 //TOPなどh2で使用
{
  @include c_txt08;
}

.c_heading02 //縦書き、ABOUT FVなど
{
  font-family: 'source-han-serif-japanese', sans-serif;
  font-weight: 100;
  font-size: 22px;
  line-height: 1;
  width: 1em;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 0.12em;
  &-ver01 //SPの時は横向き
  {
    @include v_mq(SP){
      writing-mode: initial;
      width: auto;
    }    
  }
}