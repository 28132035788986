@charset "utf-8";

.c_list01 //News、Project
{
  &.is-white{
    .c_list01{
      &__item01{
        border-color: #fff;
        color: #fff;
        &.is-hover{
          .c_list01__dd01,.c_list01__dd02{
            span{
              background: linear-gradient(#fff, #fff) 0 100% no-repeat;
              background-size: 100% 1px;
            }
          }      
        }
        &.is-out{
          .c_list01__dd01,.c_list01__dd02{
            span{
              background: linear-gradient(#fff, #fff) 0 100% no-repeat;
              background-size: 100% 0;
            }
          }
        }
      }  
    }
  }
  &__item01{
    display: flex;
    align-items: baseline;
    border-top: 1px $border-color solid;
    padding: 26.5px 0;
    color: $fc-base;
    text-decoration: none;
    padding-right: 104px;
    position: relative;
    @include v_mq(SP){
      flex-direction: column;
      padding: 24px 0;
    }   
    @include lc{
      border-bottom: 1px $border-color solid;
    }
    &.is-hover{
      .c_list01__dd01,.c_list01__dd02{
        span{
          transition: background-size .1s linear;
          background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
          background-size: 100% 1px;  
        }
      }      
    }
    &.is-out{
      .c_list01__dd01,.c_list01__dd02{
        span{
          transition: background-size .25s linear;
          background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
          background-size: 100% 0px;
        }
      }
    }
  }
  &__dt01{
    width: 300 / (300 + 558) * 100%;
    max-width: 300px;
    @include v_mq(SP){
      width: auto;
      margin-bottom: 14px;
    }
  } 
  &__dt02{
    width: 100px;
    margin-right: 100px;
    @include v_mq(SP){
      position: absolute;
      left: 0;
    }
  } 
  &__dd01{
    flex: 1;
    @include v_mq(SP){
      margin-right: 31px;
    }
  }
  &__dd02{
    flex: 1;
    @include v_mq(SP){
      margin-right: 31px;
      margin-left: 47px;
    }
  }    
  &__dd03{
    width: 100px;
    text-align: right;
    @include v_mq(SP){
      text-align: left;
      margin-left: 47px;
    }
  }  
}

.c_list02 //TOP Social Mediaのリンク
{
  display: flex;
  li{
    margin-right: 30px;
    @include lc{
      margin-right: 0;
    }
  }
}

.c_list03 //about Artists下
{
  display: flex;
  @include v_mq(SP){
    flex-wrap: wrap;
  }  
  li{
    margin-right: 30px;
    @include lc{
      margin-right: 0;
    }
  }
}

.c_list04 //about 名所画像
{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
  &__wrap01{
    @include v_mq(SP){
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
    }
  }
  &__wrap02{
    @include v_mq(SP){
      width: 60%;
    }
  } 
  &__wrap03{
    @include v_mq(SP){
      width: 40%;
      text-align: right;
      margin-right: 16px;
    }
  } 
  li{
    width: 33.3%;
    @include v_mq(SP){
      width: 100%;
    }
  }
}

.c_list05 //about history
{
  &__wrap01{
    display: flex;
    @include v_mq(SP){
      flex-direction: column;
    }    
  }
  &__wrap02{
    display: flex;
    @include v_mq(SP){
      flex-direction: column;
    }
  } 
  &__left{
    width: 100px;
  }
  &__right{
    flex: 1;
  }
  &__ttl{
    padding-bottom: 14px;
    margin-bottom: 28px;
    border-bottom: 1px solid $border-color;
    @include v_mq(SP){
      padding-bottom: 11px;
      margin-bottom: 14px;      
    }
  }
  &__content{
    display: flex;
    @include v_mq(SP){
      flex-direction: column;
    }    
  }
  &__main{
    width: 500 / (500 + 358) * 100%;
    flex-grow: 1;
    @include v_mq(SP){
      width: 100%;
    }        
  }
  &__img{
    width: 358 / (500 + 358) * 100%;
    @include v_mq(SP){
      width: 100%;
    }        
    img{
      width: 100%;
      display: block;
    }
  }
}

.c_list06 //Project詳細クレジット
{
  &__item01{
    display: flex;
    align-items: baseline;
    border-top: 1px $border-color solid;
    padding: 18px 0;
    color: $fc-base;
    text-decoration: none;
    position: relative;
    @include v_mq(SP){
      flex-direction: column;
      padding: 24px 0;
    }   
    @include lc{
      border-bottom: 1px $border-color solid;
    }
    &:hover{
      .c_list05__dd01{
        text-decoration: underline;    
      }
    }
  }
  &__dt01{
    width: 158px;
    @include v_mq(SP){
      width: auto;
      margin-bottom: 14px;
    }
  }
  &__dd01{
    flex: 1;
    text-decoration: none;
    @include v_mq(SP){
      margin-right: 31px;
    }
  }
}
