@charset "utf-8";

.c_img01{
  width: 100%;
  display: block;
}

.c_img02 //フッターイメージ
{
  width: 100%;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 100%;
    padding-top: 420 / 1366 * 100%;
    @include v_mq(SP){
      padding-top: 216 / 375 * 100%;
    }
  }
  &__txt{
    @include c_txt05;
    color: #fff;
    position: absolute;
    z-index: 1;
    top: 84px;
    right: 104px;
    @include v_mq(SP){
      top: 26px;
      right: 31px;
    }
  }
  &__inner{
    position: absolute;
    top: 0;    
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;   
    }
  }
}

.c_img03 //Top project slideなど
{
  width: 100%;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 100%;
    padding-top: 504 / 762 * 100%;
    @include v_mq(SP){
      padding-top: 227 / 344 * 100%;
    }
  }
  &-ver01{
    &:before{
      content: "";
      display: block;
      width: 100%;
      padding-top: 643 / 1158 * 100%;
      @include v_mq(SP){
        // padding-top: 227 / 344 * 100%;
      }
    }    
  }
  &-ver02 //Project詳細など。2:1画像
  {
    &:before{
      content: "";
      display: block;
      width: 100%;
      padding-top: 50%;
      @include v_mq(SP){
        padding-top: 500 / 375 * 100%;
      }
    }    
  }  
  &-ver03 //Project詳細。画像大
  {
    max-height: 750px;
    &:before{
      content: "";
      display: block;
      width: 100%;
      padding-top: 50%;
      @include v_mq(SP){
        padding-top: 210 / 375 * 100%;
      }
    }    
  }    
  &__inner{
    position: absolute;
    top: 0;    
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;   
    }
  }  
}

.c_img04 //About historyなど。キャプションが右側
{
  display: flex;
  align-items: flex-end;
  &__img{
    height: 100px;
    margin-right: 20px;
  }
  &__caption{
    transform: translateY(5px);
  }
}

.c_img05 //project詳細。画像中
{
  &__img{
    position: relative;
    max-width: 958px;
    max-height: 750px;
    margin: 0 auto;    
    @include v_mq(SP){
    }
  }
  &__inner{
    width: 100%;
    height: 100%;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;   
    }    
  }
  &__caption{
    text-align: center;
    max-height: 750px;
    margin: 0 auto;
    margin-top: 14px;   
  }
}

.c_imgset01 //project詳細。画像2カラム。
{
  display: flex;
  justify-content: flex-end;
  &__item01{
    width: 50%;
    margin-right: 42px;
    position: relative;
    @include v_mq(SP){
      margin-right: 15px;
    }
    @include lc{
      margin-right: 0;
    }
    // &:before{
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   padding-top: 370 / 558 * 100%;
    //   @include v_mq(SP){
    //     // padding-top: 227 / 344 * 100%;
    //   }
    // }
  }
  // &__inner{
  //   position: absolute;
  //   top: 0;    
  //   width: 100%;
  //   height: 100%;
  //   img{
  //     width: 100%;
  //     height: 100%;
  //     object-fit: contain;   
  //   }    
  // }
}