@charset "utf-8";

.l_section01 //1:1レイアウト
{
  display: flex;
  justify-content: flex-end;
  @include v_mq(SP){
    flex-direction: column;
  }
  &-tac{
    justify-content: center;
  }
  &-border{
    border-top: 1px solid $border-color;
    @include v_mq(SP){
      border: none;
    }
  }
  &-ver01{
    @include v_mq(SP){
      flex-direction: row;
    }
  }  
  &__left{
    width: 50%;
    @include v_mq(SP){
      width: 100%;
    }    
    &-border{
      border-right: 1px solid $border-color;
      @include v_mq(SP){
        border: none;
        border-bottom: 1px solid $border-color;
      }      
    }  
  }    
  &__right{
    width: 50%;
    @include v_mq(SP){
      width: 100%;
    }
  }
}

.l_section02{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
  &-tar{
    justify-content: flex-end;
  }
  &__left{
    width: 500 / (500 + 762) * 100%;
    @include v_mq(SP){
      width: 100%;
    }        
  }    
  &__right{
    width: 762 / (500 + 762) * 100%;
    @include v_mq(SP){
      width: 100%;
    }        
  }
}

.l_section03{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }  
  &__left{
    width: 658 / (658 + 500) * 100%;
    @include v_mq(SP){
      width: 100%;
    }    
    position: relative;
    &:before{
      content: "";
      display: block;
      width: 100%;
      padding-top: 240 / 313 * 100%;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }    
  &__right{
    width: 100% - 658 / (658 + 500) * 100%;
    padding: 56px 42px 28px 42px;
    background-color: #E4DED3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    @include v_mq(SP){
      width: 100%;
      padding: 31px;
    }        
  }
}

.l_section04{
  display: flex;
  &-ver01{
    justify-content: center;
  }
  @include v_mq(SP){
    flex-direction: column;
  }
  &-tar{
    justify-content: flex-end;
  }
  &-baseline{
    align-items: baseline;
  }
  &__left{
    width: (258 + 42) / (258 + 42 + 962) * 100%;
    @include v_mq(SP){
      width: 100%;
    }          
  }    
  &__right{
    width: 100% - ( 258 + 42 ) / (258 + 42 + 962) * 100%;
    @include v_mq(SP){
      width: 100%;
    }
    &-ver01{
      max-width: 900px;
    }
  }
}