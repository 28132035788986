@charset "utf-8";

@import "variable.scss";
@import "normalize.scss";
@import "mixin.scss";
@import "c_txt.scss";
@import "c_heading.scss";
@import "c_img.scss";
@import "c_btn.scss";
@import "c_list.scss";
@import "c_nav.scss";
@import "c_slide.scss";
@import "c_input.scss";
@import "l_header.scss";
@import "l_footer.scss";
@import "l_fv.scss";
@import "l_section.scss";
@import "l_lo.scss";
@import "l_gutenberg.scss";
@import "utility.scss";
@import "data-js.scss";

@font-face {
  font-family: 'Accent Graphic';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/AccentGraphic-Light.woff2') format('woff2'),
       url('../fonts/AccentGraphic-Light.woff') format('woff');
}

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

///////////////////////////////////////
// common ////////////////////
///////////////////////////////////////

html{
  &.wf-active{
    body{
      visibility: visible;
      opacity: 1;
    }
  }
}

body{
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s linear;  
  font-family: 'source-han-serif-japanese','Noto Sans CJK JP',YuGothic,"游ゴシック","Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",arial,Meiryo,"メイリオ",sans-serif;
  line-height: 2;
  letter-spacing: 0.04em;
  position: relative;
  color: #5A5A5A;
  background-color: $bg-beige;
  @include v_mq(PC){
    font-size: 14px;    
  }
  @include v_mq(SP){
    font-size: 14px;
  }
  &.is_no-scroll{
    overflow: hidden;
  }
}

body {
  /* フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}
@media only screen and 
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

h1,h2,h3,h4{
  margin: 0;
}

ul,ol,dl,dt,dd{
  padding: 0;
  margin: 0;
  list-style: none;
}

a{
  &:hover{
    cursor: pointer;
  }
}

p{
  @include c_txt04;
  margin: 0; 
  margin-bottom: 14px;
}

figure{
  margin: 0;
}

*{
  // &::-webkit-scrollbar {
  //   width: 7px;
  //   height: 7px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: #ccc;
  //   border-radius: 3px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: rgba(#fff, .7);
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display:none;
  // }
}
#video-container{
  width: 100%;
  height: 100vh;
}

.c_movie01{
  position: relative;
  &-ver01{
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  &:before{
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 41.5%;
  }
  &__item01{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
 &__wrap01{
    height: 100%;
    width: 100%;
    iframe{
      height: 100%;
      width: 100%;
      transform: scale(1.01);  
    }
  }
}

.c_movie02{
  width: 100%;
  padding-bottom: 41.5%;
  iframe{
    transform: scale(1.01);
  }
}

.c_movie03{
  width: 100%;
  position: relative;
  overflow: hidden;
  &:before{
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 41.5%;
  }
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.005);
  }
}

.c_box01{
  padding: 120px 104px;
  @include v_mq(SP){
    padding: 84px 0;
  }
}

.c_modal01{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(#000, .8);
  padding: 72px;
  @include v_mq(SP){
    padding: 31px 0;
  }
  &__btn{
    position: absolute;
    top: 40px;
    right: 44px;
    @include v_mq(SP){
      top: 27px;
      right: 20px; 
    }
  }
  &__content{
    iframe{
      width: 100%;
      height: 100%;
    }
  }
}