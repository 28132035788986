@charset "utf-8";

@mixin c_btn01{
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Noto Sans CJK JP', sans-serif;
  font-weight: 400;
  padding: 18px 24px 18px 20px;
  text-decoration: none;
  color: #5A5A5A;
  line-height: 1.5;
  background-image: url(../images/icon_arrow01.svg);
  background-repeat: no-repeat;
  background-position: right 24px center;
  display: block;
  text-align: left;
  // width: 100%;
  &:hover{
    color: #5A5A5A;
    pointer: cursor;
  }
  &[disabled]{
    cursor: inherit;
    opacity: .3;
    border: 1px solid rgba(#D7A85F, 1);
  }
  @include v_mq(SP){
    padding: 24px 22px 24px 16px;
    line-height: 1;
  }
  &-w258{
    @include v_mq(PC){
      width: 258px;
    }
  }
  &-tar{
    margin: 0 0 0 auto;
  }
  &-tac{
    margin: 0 auto;
  }  
  &-arrow_ver02{
    background-image: url(../images/icon_arrow02.svg);
  }
  &-w100p{
    @include v_mq(SP){
      width: 100%;
    }
  }
  &.is-hover{
    border: 1px solid rgba(#D7A85F, 1);
    transition: all .1s linear;
  }
  &.is-out{
    transition: all .25s linear;
    border: 1px solid rgba(#D7A85F, 0.3);
  }  
}
.c_btn01 //通常ボタン
{
  @include c_btn01;
}

.c_btn02{
  background-color: $bg-beige;
  width: 37px;
  height: 37px;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    pointer: cursor;
  }
  &.is-hover{
    path{
      transition: all .1s linear;
      fill: #D7A85F;
    }
  }
  &.is-out{
    path{
      transition: all .25s linear;
      fill: $fc-base;
    }
  }    
  @include v_mq(SP){
    width: 48px;
    height: 48px;
    img{
      transform: scale(1.25);
    }
  }
}

.c_btn03 //ハンバーガーメニュー開閉ボタン
{
  @mixin common01{
    display: inline-block;
    // transition: all .4s;
    box-sizing: border-box;
  }
  @include common01;
  display: block;
  position: relative;
  width: 46px;
  height: 21px;
  &:hover{
    cursor: pointer;
  }
  &-ver01{
    width: 58px;
    height: 30px;
    @include v_mq(SP){
      width: 39px;
    }    
  }  
  &__item01{
    @include common01;
    position: absolute;
    left: 0;
    width: 46px;
    height: 1px;
    background-color: #D7A85F;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 10px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
    &-ver01{
      background-color: #fff;
      width: 58px;
      @include v_mq(SP){
        width:40px;
      }
    }
  }
  &.is-close{
    .c_btn03__item01{
      &:nth-of-type(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-30deg);
        // -webkit-transform: translateY(9px) rotate(-45deg);
        // transform: translateY(9px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        bottom: 50%;
        transform: translateY(50%) rotate(30deg);
        // -webkit-transform: translateY(-9px) rotate(45deg);
        // transform: translateY(-9px) rotate(45deg);
      }
    }    
  }  
}

.c_btn04 //記事ぺーじ 戻る、進むボタン
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  @include v_mq(SP){
    width: 60px;
    height: 60px;    
  }
  &-prev{
    img,svg{
      transform: rotate(180deg);
    }
  }
  &[disabled]{
    pointer-events:none;
    opacity: 0;
  }
  &.is-hover{
    border: 1px solid rgba(#D7A85F, 1);
    transition: all .1s linear;
  }
  &.is-out{
    transition: all .5s linear;
    border: 1px solid rgba(#D7A85F, 0.3);
  }    
}