@charset "utf-8";

.l_guten01 //News詳細
{
  h1,h2,h3,h4,h5,h6{
    @include c_txt04;
    margin-bottom: 28px;
  }
  p{
    a{
      @extend .c_link05;
    }
    & + .wp-block-image{
      margin-top: 88px;
      @include v_mq(SP){
        margin-top: 51px;
      }
    }    
  }
  .wp-block-image{
    img{
      height: 100%;
      width: 100%;
      max-width: 558px;
      max-height: 500px;
      object-fit: contain;
      object-position: left;
      @include v_mq(SP){
        max-width: 558px * 0.6;
        max-height: 500px * 0.6;
      }
    }
  }
}

.l_guten02 //Project詳細
{
  & > .wp-block-image,
  .wp-block-video,
  .wp-block-embed.is-type-video,
  .wp-block-columns{
    margin-top: 84px;
    margin-bottom: 84px;
    margin-left: 204px;
    margin-right: 204px;
    @include v_mq(SP){
      margin-top: 56px;
      margin-bottom: 56px;
      margin-left: 16px;
      margin-right: 16px;
    }
    & + p {
      margin-top: 84px;
    }
    & + h1, & + h2, & + h3, & + h4, & + h5, & + h6{
      @include v_mq(SP){
        margin-top: 84px;
      }
    }
  }
  h1,h2,h3,h4,h5,h6{
    width: 50%;
    margin: 0 auto;
    margin-top: 56px;
    margin-bottom: 28px;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.12em;
    font-feature-settings: "palt" 1;
    @include v_mq(SP){
      width: auto;
      margin-right: 31px;
      margin-left: 31px;
    }
    & + .wp-block-image,
    & + .wp-block-video,
    & + .wp-block-embed.is-type-video,
    & + .wp-block-columns{
      margin-top: 28px;
    }
  }
  p{
    width: 50%;
    margin: 0 auto;
    margin-bottom: 28px;
    @include v_mq(SP){
      width: auto;
      margin: 0 31px;
      margin-bottom: 28px;
    }
    & + .wp-block-image,
    & + .wp-block-video,
    & + .wp-block-embed.is-type-video,
    & + .wp-block-columns{
      @include v_mq(SP){
        margin-top: 84px;
      }
    }
    a{
      @extend .c_link05;
    }
  }
  .wp-block-image{
    figcaption{
      font-size: 10px;
      font-family: "Noto Sans CJK JP", sans-serif;
      font-weight: 400;
      text-align: center;
      max-height: 750px;
      margin: 0 auto;
      margin-top: 14px;
    }
  }
  > .wp-block-image{
    &.is_middle, &.size-large{
      img{
        width: 100%;
        max-width: 958px;
        max-height: 750px;
        object-fit: contain;
        margin: 0 auto;
        display: block;
      }
    }
    &.is_large,&.size-full{
      margin-left: 0;
      margin-right: 0;
      .wrap{
        width: 100%;
        max-height: 750px;
        position: relative;
        &:before{
          content: "";
          display: block;
          width: 100%;
          padding-top: 50%;
        }
        img{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
        }
      }
    }
  }
  .wp-block-embed.is-type-video{
    .wp-block-embed__wrapper{
      width:100%;
      padding-top:56.25%;
      iframe {
        position:absolute;
        top:0;
        right:0;
        width:100%;
        height:100%;
      }
    }
  }
  .wp-block-columns{
    margin-left: 104px;
    margin-right: 104px;
    @include v_mq(SP){
      flex-wrap: nowrap;
      margin-left: 16px;
      margin-right: 16px;
    }
    .wp-block-column{
      margin-left: 42px !important;
      @include v_mq(SP){
        margin-left: 15px !important;
        width: 50%;
        flex-basis: auto !important;
      }
      &:first-child{
        margin-left: 0 !important;
      }
      .wp-block-image{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    &.is_same_aspect{
      .wp-block-column{
        .wp-block-image{
          .wrap{
            width: 100%;
            position: relative;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    &.is_def_aspect{
      .wp-block-column{
        .wp-block-image{
          .wrap{
            width: 100%;
            max-height: 750px;
            position: relative;
            margin-bottom: 0;
            &:before{
              content: "";
              display: block;
              width: 100%;
              padding-top: 370 / 558 * 100%;
            }
            img{
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.l_guten03 //プライバシーポリシー
{
  h3{
    @include c_txt04;
    margin-bottom: 14px;
  }
  p{
    @include c_txt01;
    margin-bottom: 56px;
    @include v_mq(SP){
      margin-bottom: 36px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}