@charset "utf-8";

//annotation
@mixin c_txt01{
  font-size: 10px;
  font-family: 'Noto Sans CJK JP', sans-serif;
  font-weight: 400;
}
.c_txt01{
  @include c_txt01;
  &-red{
    color: #CA0404;
  }  
}

.c_txt02{
  text-transform: uppercase;
}

.c_txt03 //p-en
{
  font-family: 'Accent Graphic', serif;
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.04em;
}

@mixin c_txt04{
  font-family: 'source-han-serif-japanese', sans-serif;
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.04em;
  font-weight: 400;
}
.c_txt04 //p__14-200
{
  @include c_txt04;
}

//p-tate
@mixin c_txt05{
  font-family: 'source-han-serif-japanese', sans-serif;
  font-weight: 100;
  font-size: 14px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 0.2em;
}
.c_txt05
{
  @include c_txt05;
}

//article-p--gothic
@mixin c_txt06{
  font-family: 'Noto Sans CJK JP', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 2;
}
.c_txt06
{
  @include c_txt06;
}

//button__14-200
@mixin c_txt07{
  font-family: 'Noto Sans CJK JP', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0;
}
.c_txt07
{
  @include c_txt07;
  &-red{
    color: #CA0404;
  }
}

//title__22-175
@mixin c_txt08{
  font-size: 22px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.12em;
  font-feature-settings: "palt" 1;
  &-en //title-en
  {
    font-family: 'Accent Graphic';
    font-size: 27px;
    line-height: 1.25;
    letter-spacing: 0.03em;
    font-feature-settings: unset;
  }  
}

.c_link01{
  font-size: 14px;
  font-family: 'Noto Sans CJK JP', sans-serif;
  color: $fc-base;
  text-decoration: underline;
  line-height: 1.5;
  font-weight: 400;
  &-noborder{
    text-decoration: none;
  }
  &-ver01{  
    @include v_mq(SP){
      font-size: 12px;
    }
  }
  &[data-js-hover-transiton01]{
    &.is-hover{
        background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
        background-size: 100% 1px;  
      }
      &.is-out{
        background: linear-gradient($fc-base, $fc-base) 0 100% no-repeat;
        background-size: 100% 0;
      }        
  }
}

.c_link02{
  @include c_txt08;
  &-en{
    font-size: 20px;
  }
  color: $fc-base;
  text-decoration: none;
  border-bottom: 1px solid $fc-base;
}

.c_link03{
  @include c_txt01;
  color: $fc-base;
  text-decoration: underline;
  cursor: pointer;
}

.c_link04 //子要素スタイル打ち消し
{
  color: $fc-base;
  text-decoration: none;  
}

.c_link05 // gutenberg内リンク
{
  color: $fc-base;
  text-decoration: none;
  border-bottom: 1px solid $fc-base;
}